/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 21:22:55
 * @LastEditors: Fengshi
 * @LastEditTime: 2020-11-27 15:14:40
 */
import HandleData from '@/service/Password'

export default {
  name: 'Password',
  data () {
    const self = this
    return {
      form: {
        old_password: '',
        new_password: '',
        confirm_password: ''
      },
      rules: {
        old_password: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写旧密码')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        new_password: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写新密码')
            }
            if (!/^[A-Za-z0-9|`~!@#$%^*()_+?:{},.\\/;[\]]{6,20}$/.test(val)) {
              res('6~20位，支持大小写英文或数字组合')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }],
        confirm_password: [{
          validator (rule, val, res) {
            if (val === '') {
              res('请填写确认新密码')
            }
            if (val !== self.form.new_password) {
              res('两次填写密码不一致，请检查后重新填写')
            }
            res()
          },
          trigger: 'blur',
          required: true
        }]
      },
      platformList: []
    }
  },
  async created () {
    console.log('???');
    const data = await this.$wPost('/common/user/sub_system.do')
    console.log(data);
    if (data) {
      this.platformList = data
    }
    console.log(data);
  },
  methods: {
    submit () {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const enPayload = await this.$wJsencrypt.encrypt(this.form)
          if (enPayload === false) {
            this.$refs.submitButton.updatedLoading(false)
          } else {
            const newPayload = new HandleData(enPayload).submit()
            const data = await this.$wPost('/common/user/update_password.do', newPayload)
            if (data === true) {
              this.$wToast.success('修改密码成功，请重新登录')
              setTimeout(() => {
                location.reload()
              }, 200);
            } else {
              this.$refs.submitButton.updatedLoading(false)
            }
          }
        } else {
          this.$refs.submitButton.updatedLoading(false)
        }
      })
    },
    cancel () {
      history.go(-1)
    },
    // 去除密码框中的空格
    trimStr (obj, val) {
      this[obj][val] = this[obj][val].replace(/\s+/g, '');
    }
  }
}
