/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 22:13:59
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 22:16:53
 */
class HandleData {
  constructor (payload) {
    this.payload = payload
  }

  submit () {
    const newPayload = {
      old_password: this.payload.old_password,
      new_password: this.payload.new_password
    }

    return newPayload
  }
}

export default HandleData
