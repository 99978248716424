/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-23 21:22:45
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-23 21:24:17
 */
import Password from './Password.vue'
export default Password
